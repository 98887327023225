<template>
    <div class="wrapper">
        <div class="tit">How to place an order</div>
        <div>
            <div class="title">Step 1:</div>
            <p style="margin-bottom: 20px;">
                Before placing an order, you must have a GiGwi account.
                （If you already have an account, please skip this step.Go to Step 2）
            </p>
            <p class="titles">1.1 Register an account.</p>
            <p>
                Click to enter the "sign up" page from the navigation bar.
            </p>
            <div>
                <img src="../assets/imgs/uploads/1register.png" alt="" style="width: 100%;height: 100%;">
            </div>
            <el-divider></el-divider>
            <p>
                Fill in the corresponding information step by step. Please write your information as clearly and in detail
                as possible. The content marked with an asterisk is required to be filled in.
            </p>
            <el-divider></el-divider>
            <div>
                <img src="../assets/imgs/uploads/2info.png" alt="" style="width: 100%;height: 100%;">
                <img src="../assets/imgs/uploads/3info.png" alt="" style="width: 100%;height: 100%;">
                <img src="../assets/imgs/uploads/4info.png" alt="" style="width: 100%;height: 100%;">
                <img src="../assets/imgs/uploads/5info.png" alt="" style="width: 100%;height: 100%;">
                <img src="../assets/imgs/uploads/6info.png" alt="" style="width: 100%;height: 100%;">
            </div>
            <el-divider></el-divider>
            <p>
                After filling in all the information, it have to be checked by the GiGwi sales manager. Generally, it will
                be done in 1 working day. After the registration is passed, the account and password will be sent to your
                email.
            </p>
            <p>If you do not receive response, you can also contact us via info@gigwi.com.hk </p>
            <el-divider></el-divider>
            <p class="titles">1.2 When you get the account,firstly you'd better to change a password that is easy for you to
                remember.(follow as below.)</p>
            <el-divider></el-divider>
            <div>
                <img src="../assets/imgs/uploads/7pwd.png" alt="" style="width: 100%;height: 100%;">
                <img src="../assets/imgs/uploads/8pwd.png" alt="" style="width: 100%;height: 100%;">
            </div>
            <div class="title">Step 2:</div>
            <p class="titles">2.1Choose the products which you want to buy.</p>
            <p>
                Click the “place an order(under the button Products at navigation bar)” for quickly chose.
            </p>
            <el-divider></el-divider>
            <div>
                <img src="../assets/imgs/uploads/9order.png" alt="" style="width: 100%;height: 100%;">
            </div>
            <el-divider></el-divider>
            <p>By default, you will select products from our inventory.</p>
            <p>You can also choose more products from “Pre-order”. However, for these products, the MOQ is 1200 pic, and we
                need two months for production.</p>
            <el-divider></el-divider>
            <div>
                <img src="../assets/imgs/uploads/10order.png" alt="" style="width: 100%;height: 100%;">
            </div>
            <el-divider></el-divider>
            <p>You can click “more” to view more product informations.</p>
            <el-divider></el-divider>
            <div>
                <img src="../assets/imgs/uploads/11order.png" alt="" style="width: 100%;height: 100%;">
                <img src="../assets/imgs/uploads/12order.png" alt="" style="width: 100%;height: 100%;">
            </div>
            <el-divider></el-divider>
            <p>When you update the quantity, it will be automatically added to your shopping cart. The quantity add or minus
                MOQ each time(one carton). If your order quantity is not match,Pls communicate with our salesman after
                placing the order, and if it can be, our salesman will modify the order.</p>
            <el-divider></el-divider>
            <div>
                <img src="../assets/imgs/uploads/13order.png" alt="" style="width: 100%;height: 100%;">
            </div>
            <el-divider></el-divider>
            <p class="titles">2.2 place an order</p>
            <p>Once the order is submitted from shopping cart, the customer cannot modify it. If you need to change the
                order, you have to contact our salesman.</p>
            <el-divider></el-divider>
            <div>
                <img src="../assets/imgs/uploads/14order.png" alt="" style="width: 100%;height: 100%;">
            </div>
            <el-divider></el-divider>
            <p class="titles">2.3 If this is the first time you submit an order on our website, you need to fill in the
                shipping address (which may be your local warehouse or a warehouse in China designated by your own logistic
                company).</p>
            <el-divider></el-divider>
            <div>
                <img src="../assets/imgs/uploads/15order.png" alt="" style="width: 100%;height: 100%;">
                <img src="../assets/imgs/uploads/16order.png" alt="" style="width: 100%;height: 100%;">
            </div>
            <el-divider></el-divider>
            <p class="titles">2.4 submit the order.</p>
            <el-divider></el-divider>
            <div>
                <img src="../assets/imgs/uploads/17order.png" alt="" style="width: 100%;height: 100%;">
                <img src="../assets/imgs/uploads/18order.png" alt="" style="width: 100%;height: 100%;">
            </div>
            <el-divider></el-divider>
            <p style="margin-bottom: 30px;">After you submit the order, our salesman will contact you within 1 working day.
                If you do not receive
                response, you can also contact us via info@gigwi.com.hk </p>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.wrapper {
    width: 1280px;
    margin: 0 auto;
    color: #000;
}

.tit {
    font-size: 36px;
    font-weight: bold;
    margin: 69px 0 31px;
}

.title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}

.titles {
    margin-top: 10px;
    margin-bottom: 15px;
    font-weight: bold;
}
</style>